import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import React from "react";
const Faq = ({ className }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <section className={`uniqueiit-hero-area  ${className || ""}`}>
      <section className="shadow-blue white-bg padding"> 
       <div className="section-title text-center">
            <h2 className="title">
              FAQ <span>Unique IIT</span>
            </h2>
          </div>
        <div>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "100%", flexShrink: 0 , fontWeight: "bold"}}>
                What services does UniqueIIT offer?
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                UniqueIIT specializes in custom software solutions, digital
                marketing, branding, cloud infrastructure services, digital
                strategy, website development, UI/UX design, and more.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ width: "100%", flexShrink: 0 , fontWeight: "bold"}}>
                {" "}
                How does UniqueIIT approach digital marketing and branding?
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>
            You are currently not an owner
          </Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We focus on crafting strategies that resonate with your
                audience, embody your core values, and ensure an enhanced online
                presence, leading to increased engagement and brand visibility.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ width: "100%", flexShrink: 0 , fontWeight: "bold"}}>
                Can you elaborate on your cloud infrastructure services?
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>
            Filtering has been entirely disabled for whole web server
          </Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Our cloud infrastructure services ensure scalability, security,
                and efficiency in business operations, offering a robust
                foundation for growth and development.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography sx={{ width: "100%", flexShrink: 0 , fontWeight: "bold"}}>
                {" "}
                What sets UniqueIIT apart in terms of software solutions?
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We pride ourselves on delivering high-quality, reliable, and
                secure software solutions that adhere to the highest industry
                standards, ensuring optimal performance and functionality.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "100%", flexShrink: 0 , fontWeight: "bold"}}>
                How does UniqueIIT approach website development and design?
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We excel in creating visually stunning, user-friendly websites
                optimized for performance and search engine visibility, ensuring
                an exceptional user experience.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "100%", flexShrink: 0 , fontWeight: "bold"}}>
                What makes UniqueIIT a one-stop solution for businesses seeking
                technological advancement?
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Our comprehensive suite of services, ranging from software
                development to digital strategy and UI/UX design, allows us to
                cater to diverse technological needs for strategic growth.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "100%", flexShrink: 0 , fontWeight: "bold"}}>
                How does UniqueIIT ensure client satisfaction?
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We are committed to understanding our clients' needs, providing
                personalized solutions, maintaining transparent communication,
                and delivering results that exceed expectations.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "100%", flexShrink: 0 , fontWeight: "bold"}}>
                Can UniqueIIT accommodate specific business requirements?
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Absolutely! We pride ourselves on our ability to tailor
                solutions according to individual business needs, ensuring
                flexibility and adaptability.{" "}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel9"}
            onChange={handleChange("panel9")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "100%", flexShrink: 0 , fontWeight: "bold"}}>
                What industries does UniqueIIT cater to?
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Our expertise spans across various industries, catering to
                businesses of all sizes seeking technological advancement and
                strategic growth.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel10"}
            onChange={handleChange("panel10")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "100%", flexShrink: 0 , fontWeight: "bold"}}>
                How can I get in touch with UniqueIIT for further inquiries or
                collaboration?
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You can reach out to us through our website's contact form or
                directly via email/phone. Our team is always ready to assist you
                and discuss your requirements in detail.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </section>
    </section>
  );
};

export default Faq;
