import React from "react";
import "../../App.css";
import herosvgmainimg from '../../assets/images/svg/product-iteration-animate.svg';
const AboutUs = ({ className }) => {
  return (
    <>
      <section className={`uniqueiit-hero-area  ${className || ""}`}>
        <section className="shadow-blue white-bg padding">
          <div className="section-title text-center">
            <h2 className="title">
              About <span>Unique IIT</span>
            </h2>
          </div>
          <div className="affu">
            <div>
              <img
                src={herosvgmainimg}
                alt=""
                style={{ width: "400px" }}
              />
            </div>
            <div className="ourcompany">
              <h2>Our Company</h2>
              <p style={{ textAlign: "justify" }}>
                UniqueIIT, we specialize in developing comprehensive, all-in-one
                custom software solutions, Digital Marketing & Branding. Our
                passion for cutting-edge technology with a commitment to
                empowering businesses in today’s dynamic and competitive
                landscape. Our expertise encompasses brand digital marketing,
                ensuring enhanced online presence and audience engagement. In
                brand strategy, we focus on delivering messages that resonate
                with target audiences and embody core values. Our cloud
                infrastructure services offer scalable, secure, and efficient
                business operations. We are forward-looking in digital strategy,
                positioning businesses at the forefront of digital innovation.
                UniqueIIT excels in creating visually stunning, user-friendly
                websites, optimized for performance and search engine
                visibility. We assure high-quality, reliable, and secure
                software solutions, adhering to the highest industry standards.
                Additionally, our intuitive and engaging UI/UX designs are
                tailored to provide exceptional user experiences, making us a
                one-stop solution for businesses seeking technological
                advancement and strategic growth.
              </p>
            </div>
          </div>
        </section>
      </section>
      <section className="shadow-blue white-bg padding">
        <div>
          <div className="section-title text-center">
            <h2 className="title">
              <span>Industries</span>
            </h2>
          </div>
          <div className="indus">
            <div className="induscard">
              <img
                src="https://img.icons8.com/?size=48&id=gShesypkbo1o&format=png"
                alt="Technology"
              />
              <a>Technology</a>
            </div>
            <div className="induscard">
              <img
                src="https://img.icons8.com/?size=48&id=BCEBQsyDSAeO&format=png"
                alt="Technology"
              />
              <a>Digital Marketing</a>
            </div>
            <div className="induscard">
              <img
                src="https://img.icons8.com/?size=48&id=52580&format=png"
                alt="Technology"
              />
              <a>Brand Strategy</a>
            </div>
            <div className="induscard">
              <img
                src="https://img.icons8.com/?size=48&id=3FDJsdSpK6cv&format=png"
                alt="Technology"
              />
              <a>Cloud Infrastructure</a>
            </div>
            <div className="induscard ">
              <img
                src="https://img.icons8.com/?size=48&id=63275&format=png"
                alt="Healthcare service"
              />
              <a>Healthcare service</a>
            </div>
            <div className="induscard">
              <img
                src="https://img.icons8.com/?size=48&id=6whrf4GsqiI9&format=png"
                alt="Insurance"
              />
              <a>Insurance</a>
            </div>

            <div className="induscard">
              <img
                src="https://img.icons8.com/?size=48&id=114446&format=png"
                alt="Tour & Travels"
              />
              <a>Tour & Travels</a>
            </div>
            <div className="induscard">
              <img
                src="https://img.icons8.com/?size=45&id=13011&format=png"
                alt="Retail (Ecommerce)"
              />
              <a>Retail (Ecommerce)</a>
            </div>
            <div className="induscard">
              <img
                src="https://img.icons8.com/?size=43&id=FYXRDHzA04lT&format=png"
                alt="Campus Management"
              />
              <a>Campus Management</a>
            </div>
          </div>
        </div>
      </section>
      <section
        className={`appie-testimonial-area pt-50 pb-[25px] ${className || ""}`}
        id="testimonial"
      >
        <div className="office appie-title ">
          <div className="officedetails">
            <h3>Office Location</h3>
            <p>
              Accelerate your digital transformation, scale development efforts
              flexibly and efficiently from web and mobile applications to
              cloud-based systems, we have the skills and experience to bring
              your project to life. Contact us today to discuss your software
              needs.
            </p>
            <a
              href="https://www.google.com/maps/place/Unique-IIT/@28.5252235,77.32648,17z/data=!3m1!4b1!4m6!3m5!1s0x390ce6897c5ddff5:0x83edb98617a55e08!8m2!3d28.5252188!4d77.3290549!16s%2Fg%2F11tsdb_3p4?entry=ttu"
              target="_blank"
              className="officebutton contact-us-btn"
            >
              <i className="fab fa-contact" /> Location
            </a>
          </div>
          <div className="officemap"
          style={{ width: "50%" }}>
            <img
              src={require("../../assets/images/maps.png")}
              alt=""
              style={{ height: "350px"}}
            />
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d219.09318142187854!2d77.32928623632894!3d28.524960922134834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce6897c5ddff5%3A0x83edb98617a55e08!2sUnique-IIT!5e0!3m2!1sen!2sin!4v1701855571668!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
