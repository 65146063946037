import React, { useEffect } from "react";
import useToggle from "../Hooks/useToggle";
import FooterHomeOne from "../components/HomeOne/FooterHomeOne";
import HomeOneHeader from "../components/HomeOne/HomeOneHeader";
import ServicesHomeOne from "../components/HomeOne/ServicesHomeOne";
import Drawer from '../components/Mobile/Drawer';
import StickyMenu from "../lib/StickyMenu";

const servicePage = () => {
  useEffect(() => {
    StickyMenu();
});
const [drawer, drawerAction] = useToggle(false);
  return (
    <div>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <HomeOneHeader action={drawerAction.toggle} className="servicenavbar" />
      <ServicesHomeOne />
      <FooterHomeOne />
    </div>
  );
};

export default servicePage;
