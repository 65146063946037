import React, { useState } from "react";

const JobForm = () => {
    const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isTermsChecked) {
      setErrorMessage('You must agree to the Terms & Conditions before proceeding.');
    } else {
      setErrorMessage('');
      // Process your form submission here
    }
  };

  const handleCheckboxChange = (event) => {
    setIsTermsChecked(event.target.checked);
    if (event.target.checked) {
      setErrorMessage('');
    }
  };
  return (
    <>
     
        <div className="container pt-100">
          <div className="row">
            <div className="col-md-12">
              <div className="contact-form">
                {/* <h4>Let’s Connect</h4>
                    <p>Integer at lorem eget diam facilisis lacinia ac id massa.</p> */}
                <h4>Job Application Form</h4>
                <p>
                  Please Fill Out the Form Below to Submit Your Job Application!
                </p>
                <form onSubmit={handleSubmit} action="#" method="post" className="row">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="f-name"
                      placeholder="First Name"
                      required
                      title="You must agree to the Terms & Conditions before proceeding."
                      
                    />
                    
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="l-name"
                      placeholder="Last Name"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="number"
                      name="phone"
                      placeholder="Phone Number"
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <input
                      type="text"
                      name="experience"
                      placeholder="Experience"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="qualification"
                      placeholder="Qualification"
                      required
                    />
                  </div>
                  <div class="job col-md-6">
                    <select name="position">
                      <option value="">Select Position</option>
                      <option value="Frontend Developer">
                        Frontend Developer
                      </option>
                      <option value="Backend Developer">
                        Backend Developer
                      </option>
                      <option value="Full Stack Developer">
                        Full Stack Developer
                      </option>
                      <option value="Digital Marketing">
                        Digital Marketing
                      </option>
                      <option value="Graphic Designer">Graphic Designer</option>
                      <option value="Content Writer">Content Writer</option>
                      <option value="Video Editor">Video Editor</option>
                    </select>
                  </div>
                  <div class="resume col-md-6">
                    <label htmlFor="">Upload Resume :-</label>
                    <input
                      type="file"
                      name="resume"
                      accept=".pdf,.doc,.docx" // Specify the file types to accept, usually for resumes it's PDF and Word documents
                      placeholder="Upload Resume"
                      required
                    />
                  </div>

                  {/* <div className="col-md-12">
                  <input type="text" name="suject" placeholder="Subject" />
                </div> */}
                  {/* <div className="col-md-12">
                  <textarea
                    name="message"
                    placeholder="How can we help?"
                  ></textarea>
                </div> */}
                  {/* <div class="col-md-6">
                    <div class="condition-check">
                      <input
                        id="terms-conditions"
                        type="checkbox"
                        required
                        title="You must agree to the Terms & Conditions before proceeding."
                      />
                      <label htmlFor="terms-conditions">
                        I agree to the <a href="#">Terms & Conditions</a>
                      </label>
                    </div>
                  </div> */}
                   <div className="col-md-6">
        <div className="condition-check">
          <input
            id="terms-conditions"
            type="checkbox"
            checked={isTermsChecked}
            onChange={handleCheckboxChange}
            // Note: The required attribute is not necessary since we're handling validation manually
          />
          <label htmlFor="terms-conditions">
            I agree to the <a href="#" target="_blank">Terms & Conditions</a>
          </label>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
      </div>

                  <div className="col-md-6 text-right">
                    <input type="submit" name="submit" value="Confirm Apply" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
   
    </>
  );
};

export default JobForm;
