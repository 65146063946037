import React from 'react'
import Portfolios from './Portfolios'

const ProjectPortfolio = ({ className }) => {
    return (
<>

       <section className={`uniqueiit-hero-area ${className || ''}`}>
        {/* <div className='row justify-content-center'>
          <div className='col-lg-6'>
            <div className='section-title text-center'>
              <h6 className='sub-title'>Gallery Here</h6>
              <h2 className='title'>
                Latest <span>Project</span> Are Here
              </h2>
            </div>
          </div>
        </div> */}
            <div className="container">
                <section className="shadow-blue white-bg padding">

                    <div>

                        <Portfolios />
                    </div>

                </section>
            </div>
        </section>
        </>

    )
}

export default ProjectPortfolio
