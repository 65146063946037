import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Contact from "./components/Contact";
import Error from "./components/Error";
import Loader from "./components/Helper/Loader";
import ScrollToTop from "./components/Helper/ScrollToTop";
import HomeDark from "./components/HomeDark";
import HomeOne from "./components/HomeOne";
import Service from "./components/Service";
import AboutUsPage from "./pages/AboutUsPage";
import CareerPage from "./pages/CareerPage";
import FaqPage from "./pages/FaqPage";
import jobformPage from "./pages/JobformPage";
import PrivacyPage from "./pages/PrivacyPage";
import WorkDetails from "./pages/WorkDetails";
import projectPage from "./pages/projectPage";
import servicePage from "./pages/servicePage";

function Routes() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  });
  return (
    <>
      {loading && (
        <div className={`appie-loader ${loading ? "active" : ""}`}>
          <Loader />
        </div>
      )}
      <div className={`appie-visible ${loading === false ? "active" : ""}`}>
        <Router>
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={HomeOne} />

              <Route exact path="/home-dark" component={HomeDark} />

              <Route exact path="/service" component={Service} />
              <Route exact path="/service-unique" component={servicePage} />
              <Route exact path="/project-unique" component={projectPage} />

              {/* <Route exact path="/about-us" component={AboutUs} /> */}

              <Route exact path="/about-us" component={AboutUsPage} />
              <Route exact path="/faq" component={FaqPage} />
              <Route exact path="/privacy-policy" component={PrivacyPage} />

              <Route exact path="/contact" component={Contact} />
              <Route exact path="/career" component={CareerPage} />
              <Route exact path="/job" component={jobformPage} />
              <Route path="/works/:id/:title" component={WorkDetails} />
              <Route exact path="/error" component={Error} />

              <Route component={Error} />
            </Switch>
          </ScrollToTop>
        </Router>
      </div>
    </>
  );
}

export default Routes;
