import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.scss";
import Routes from './Routes';
import "./sass/custom.css";


function App() {
    return <Routes />;
}

export default App;
