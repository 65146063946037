import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import profileImage2 from '../../assets/images/team/4.png';
import profileImage1 from '../../assets/images/team/mufazzal hussain.jpeg';


function TestimonialHomeOne({ className }) {


    const testimonials = [
        {
            name: 'Muffazal Hussain',
            date: 'June 14, 2022',
            content: `'Your Learning Management System is a game-changer for online education. The platform's
            user-friendly interface and robust features make learning a breeze. The interactive quizzes
            and progress tracking kept me engaged and motivated throughout the courses. It's clear that a
            lot of thought went into creating an intuitive experience. Kudos to your team for crafting a
            fantastic learning journey!'`,
            rating: 5,
            profileimage: profileImage1,
        },
        {
            name: 'Najib Akhtar',
            profileimage: 'test2',
            date: 'Dec 20, 2022',
            content: `'consultation discover apartments. ndulgence off
            under folly death wrote cause her way spite. Plan
            upon yet way get cold spot its week. Almost do am or
            limits hearts. Resolve parties but why she shewing.
            She sang know now always remembering to the point.'`,
            rating: 5,
            profileimage:profileImage2
        },
        // Add more testimonials as needed
    ];


    const sliderRef = useRef();
    const sliderNext = () => {
        sliderRef.current.slickNext();
    };
    const sliderPrev = () => {
        sliderRef.current.slickPrev();
    };
    return (
        <section
            className={`appie-testimonial-area pt-100 pb-160 ${className || ''}`}
            id="testimonial"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="appie-testimonial-slider" style={{ position: 'relative' }}>
                            <span
                                className="prev slick-arrow"
                                style={{ display: 'block' }}
                                onClick={sliderNext}
                                role="button"
                                tabIndex="0"
                            >
                                <i className="fal fa-arrow-left" />
                            </span>
                            <Slider ref={sliderRef} dots arrows={false}>
                                {/* <div className="appie-testimonial-item text-center">
                                    <div className="author-info">
                                        <img src={user} alt=""  width={50}/>
                                        <h5 className="title">Muffazal Hussain</h5>
                                        <span>June 14, 2022</span>
                                    </div>
                                    <div className="text">
                                        <p>
                                        “Your Learning Management System is a game-changer for online education. The platform's
                          user-friendly interface and robust features make learning a breeze. The interactive quizzes
                          and progress tracking kept me engaged and motivated throughout the courses. It's clear that a
                          lot of thought went into creating an intuitive experience. Kudos to your team for crafting a
                          fantastic learning journey!”
                                        </p>
                                        <ul>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="appie-testimonial-item text-center">
                                    <div className="author-info">
                                        <img src={user} alt="" width={50} />
                                        <h5 className="title">Jason Response</h5>
                                        <span>April 14, 2022</span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Why I say old chap that is spiffing lurgy Oxford
                                            cracking goal bleeder, bog-standard Harry brilliant mush
                                            a blinding shot starkers.
                                        </p>
                                        <ul>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="appie-testimonial-item text-center">
                                    <div className="author-info">
                                        <img src={user} alt="" width={50} />
                                        <h5 className="title">Jason Response</h5>
                                        <span>April 14, 2022</span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Why I say old chap that is spiffing lurgy Oxford
                                            cracking goal bleeder, bog-standard Harry brilliant mush
                                            a blinding shot starkers.
                                        </p>
                                        <ul>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                        </ul>
                                    </div>
                                </div> */}


                                {testimonials.map((testimonial, index) => (
                                    <div className="appie-testimonial-item text-center" key={index}>
                                        <div className="author-info">
                                            <img src={testimonial.profileimage} alt="" width={50} />
                                            <h5 className="title">{testimonial.name}</h5>
                                            <span>{testimonial.date}</span>
                                        </div>
                                        <div className="text">
                                            <p>{testimonial.content}</p>
                                            <ul>
                                                {Array.from({ length: testimonial.rating }, (_, i) => (
                                                    <li key={i}>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                ))}


                            </Slider>
                            <span
                                onClick={sliderPrev}
                                role="button"
                                tabIndex="-1"
                                className="next slick-arrow"
                                style={{ display: 'block' }}
                            >
                                <i className="fal fa-arrow-right" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TestimonialHomeOne;
