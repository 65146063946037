import React, { useEffect, useState } from "react";
import Portfolio from "../Items/Portfolio.jsx";

const filters = [
  {
    id: 1,
    name: "All Projects",
  },

  {
    id: 4,
    name: "E-Commerce",
  },
  {
    id: 5,
    name: "LMS Website",
  },
  {
    id: 3,
    // name: "Hotel Management System",
    name: "Hotel MS",
  },
  {
    id: 2,
    name: "HRMS",
  },
  {
    id: 7,
    name: "SCMS",
  },
  {
    id: 6,
    name: "Portfolio",
  },
];

const allData = [
  {
    id: 1,
    name: "Learning Management System",
    category: ["lms website"],
    image: "images/portfolio/lms-portfolio.jpg",
    // slug: LearningManagementSystem,
    slug: "Learning-Management-System",

  },

  {
    id: 2,
    name: "HR Management System",
    category: ["hrms"],
    image: "images/portfolio/HRMS-portfolio.jpg",
    slug: "HR-Management-System",
  },
  {
    id: 3,
    name: "Sales Commission Management System",
    category: ["scms"],
    image: "images/portfolio/SCMS-portfolio.jpg",
    slug: "scms",
  },
  {
    id: 4,
    name: "Hotel Management System",
    category: ["hotel ms"],
    image: "images/portfolio/HMS-portfolio.jpg",
    slug: "Hotel-Management-System",
  },
  {
    id: 5,
    name: "Multivendor Product E-Commerce",
    category: ["e-commerce"],
    image: "images/portfolio/E Commerce -portfolio.jpg",
    slug: "Multivendor Product E-Commerce",
  },
  {
    id: 6,
    name: "More Project",
    category: ["e-commerce", "hotel ms", "scms", "hrms", "lms website"],
    image: "images/portfolio/CommingSoon-Project.jpg",
    // slug: "more-project",
  },
  
];

function Portfolios() {
  const [getAllItems] = useState(allData);
  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(3);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [noMorePost, setNoMorePost] = useState(false);

  useEffect(() => {
    setActiveFilter(filters[0].name.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= 6));
  }, [getAllItems]);

  const handleChange = (e) => {
    console.log(e.target.value);
    e.preventDefault();
    let targetFilter = e.target.value
      ? e.target.value.toLowerCase()
      : e.target.textContent.toLowerCase();
    setActiveFilter(targetFilter);
    let tempData;
    if (targetFilter === filters[0].name.toLowerCase()) {
      tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
    } else {
      tempData = getAllItems.filter((data) => {
        return (
          data.category.includes(targetFilter) && data.id <= dataVisibleCount
        );
      });
    }
    setVisibleItems(tempData);
  };

  const handleLoadmore = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;

    if (tempCount > getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].name.toLowerCase()) {
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        let items = getAllItems.filter((data) => {
          return data.category.includes(activeFilter) && data.id <= tempCount;
        });
        setVisibleItems(items);
      }
    }
  };

  return (
    // <>
    //   <ul className="portfolio-filter list-inline">
    //     {filters.map((filter) => (
    //       <li
    //         className={
    //           filter.name.toLowerCase() === activeFilter
    //             ? "list-inline-item current"
    //             : "list-inline-item"
    //         }
    //         key={filter.id}
    //         onClick={handleChange}
    //       >
    //         {filter.name}
    //       </li>
    //     ))}
    //   </ul>

    //   <div className="pf-filter-wrapper mb-4">
    //     <select
    //       className="portfolio-filter-mobile"
    //       onChange={(e) => handleChange(e)}
    //     >
    //       {filters.map((filter) => (
    //         <option value={filter.name} key={filter.id}>
    //           {filter.name}
    //         </option>
    //       ))}
    //     </select>
    //   </div>

    //   <div className="row portfolio-wrapper">
    //     {visibleItems.map((item) => (
    //       <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
    //         <Portfolio portfolio={item} />
    //       </div>
    //     ))}
    //   </div>

    //   {noMorePost ? null : (
    //     <div className="load-more text-center mt-4">
    //       <a
    //         href="#!"
    //         className="btn btn-default"
    //         onClick={(e) => handleLoadmore(e)}
    //       >
    //         <i className="fas fa-circle-notch"></i> Load more
    //       </a>
    //     </div>
    //   )}
    // </>
    <>
    <div className='row justify-content-center'>
          <div className='col-lg-6'>
            <div className='section-title text-center'>
              <h6 className='sub-title'>Gallery Here</h6>
              <h2 className='title'>
                Latest <span>Project</span> Are Here
              </h2>
            </div>
          </div>
        </div>
   
      <ul className="portfolio-filter list-inline">
        {filters.map((filter) => (
          <li
            className={
              filter.name.toLowerCase() === activeFilter
                ? "list-inline-item current"
                : "list-inline-item"
            }
            key={filter.id}
            onClick={handleChange}
          >
            {filter.name}
          </li>
        ))}
      </ul>

      <div className="pf-filter-wrapper mb-4">
        <select
          className="portfolio-filter-mobile"
          onChange={(e) => handleChange(e)}
        >
          {filters.map((filter) => (
            <option value={filter.name} key={filter.id}>
              {filter.name}
            </option>
          ))}
        </select>
      </div>

      <div className="row portfolio-wrapper">
        {visibleItems.map((item) => (
          <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
            <Portfolio portfolio={item} />
          </div>
        ))}
      </div>

      {noMorePost ? null : (
        <div className="load-more text-center mt-4">
          <a
            href="#!"
            className="btns btn-defaults"
            onClick={(e) => handleLoadmore(e)}
          >
            <i className="fas fa-circle-notch"></i> Load more
          </a>
        </div>
      )}

    
    </>
  );
}

export default Portfolios;
