import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import shapeTwo from '../../assets/images/shape/shape-2.png';
import shapeThree from '../../assets/images/shape/shape-3.png';
import shapeFour from '../../assets/images/shape/shape-4.png';
import herosvgmainimg from '../../assets/images/svg/product-iteration-animate.svg';
import ContactusDialogbox from './ContactusDialogbox';

function HeroHomeOne({ className }) {
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
      setModalOpen(true);
    };
  
    // const closeModal = () => {
    //   setModalOpen(false);
    // };
    const handleClose = () => {
      setModalOpen(false);
    };

  
    return (
        <>
        <Dialog
          fullWidth
          maxWidth="md"
          open={modalOpen}
          onClose={handleClose}
          onBackdropClick={true}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              container
            >
              <Grid item xs={12} sm={11}>
                {/* style={{ justifyContent: 'center' }} */}
                <Grid container>
                  <Typography
                    style={{ display: "inline-block" }}
                    variant="h5"
                    fontWeight={700}
                  >
                  
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Button
                  color="secondary"
                  onClick={handleClose}
                  variant="contained"
                >
                  &#10539;
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <div>
               {/* <Forms/> */}
               <ContactusDialogbox/>
            </div>
          </DialogContent>
        </Dialog>
            <section className={`appie-hero-area pb-2 ${className || ''}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-hero-content">
                                <span>Welcome To </span>
                                <h1 className="appie-title">
                                    <span>Unique IIT </span> <br /> Your Software Development Outsourcing Partner
                                </h1>
                                <p>
                                    Accelerate your digital transformation,
                                    scale development efforts flexibly and efficiently
                                    from web and mobile applications to cloud-based systems,
                                    we have the skills and experience to bring your project to life.
                                    Contact us today to discuss your software needs. 
                                    
                                </p>
                                <ul>
                                <li>
                                    
                                    <a href="#" className='contact-us-btn' onClick={openModal}>
                                        <i className="fab fa-contact" /> REQUEST SERVICES 
                                        
                                    </a>
                                    
                                </li>
                                 


                                    <li>
                                        {/* <a href="#" className='contact-us-btn'>
                                            <i className="fab fa-contact" /> REQUEST SERVICES
                                        </a> */}
                                        <ScrollLink
                                            activeClass="active"
                                            to="section-portfolios"
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                            offset={50}
                                            className='contact-us-btn'

                                        >

                                            <i className="fab fa-contact" /> BROWSE PRODUCTS

                                        </ScrollLink>
                                    </li>
                                  
                                    {/* <li>
                                        <a className="item-2" href="#">
                                            <i className="fab fa-google-play" /> Download for
                                            Android
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-hero-thumb">
                                <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    {/* <img src={heroThumbOne} alt="" /> */}
                                    <img src={herosvgmainimg} alt="uniqueiit main img" />


                                </div>
                                {/* <div
                                    className="thumb-2 wow animated fadeInRight"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="600ms"
                                >
                                    <img src={heroThumbTwo} alt="" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-shape-1">
                    <img src={shapeTwo} alt="" />
                </div>
                <div className="hero-shape-2">
                    <img src={shapeThree} alt="" />
                </div>
                <div className="hero-shape-3">
                    <img src={shapeFour} alt="" />
                </div>
            </section>
        </>
    );
}

export default HeroHomeOne;
