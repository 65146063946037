import React from "react";

const Career = ({ className }) => {
  return (
    <section className={`uniqueiit-hero-area mt-0 ${className || ""}`}>
      <section className="shadow-blue white-bg padding ">
        <div className="section-title text-center">
          <h2 className="title">
            Career <span>Unique IIT</span>
          </h2>
        </div>
        <h4>
          At Unique IIT, we're dedicated to advancing professional careers.
        </h4>
        <section className="shadow-blue white-bg padding ">
          <h4>Join Us</h4>
          <p style={{ textAlign: "justify" }}>
            Embrace the UniqueIIT team and step into a collaborative environment
            dedicated to revolutionizing the tech landscape with bespoke
            solutions worldwide. Enjoy competitive benefits, generous PTO, and
            flexible work options—remote, hybrid, or on-premise—to ensure your
            work-life equilibrium.
          </p>
          <p style={{ textAlign: "justify" }}>
            At UniqueIIT, you'll shape a growing company while nurturing your
            personal and professional balance. If you're driven to redefine
            excellence in the tech realm, UniqueIIT is your gateway to making
            that impactful next career choice.
          </p>
          <div className="career">
            <div className="careerone">
              <h4>Unique IIT Career</h4>

              <p
                style={{
                  backgroundColor: "#f5f4f4",
                  padding: "10px",
                  borderRadius: "12px",
                }}
              >
                At UniqueIIT, our foundation rests on a team-driven culture,
                fostering a positive environment among an exceptional staff, all
                driven by a sincere dedication to delivering top-notch service
                worldwide. We strongly believe that nurturing our team's growth
                is pivotal, enabling us to provide exceptional customer
                experiences and establish an industry benchmark for excellence.
              </p>
            </div>
            <div className="careertwo">
              <h5>We have career opportunities for:</h5>
              <li>Frontend Developer</li>
              <li>Backend Developer</li>
              <li>Full Stack Developer</li>
              <li>Digital Marketing</li>
              <li>Graphic Designer</li>
              <li>Content Writer</li>
              <li>Video Editor</li>
            </div>
          </div>
        </section>
        <section
          className={`appie-testimonial-area pt-20 pb-30 ${className || ""}`}
          id="testimonial"
        >
          <div className="currentOpportunities">
            <h3 style={{ color: "white", fontWeight: "300" }}>
              Current Opportunities
            </h3>
            <hr />
            <h4 style={{ textAlign: "center", color: "white" }}>
              Full Stack Developer
            </h4>
            <p style={{ textAlign: "center", color: " white", margin: "30px" }}>
              Are you enthusiastic about web development, both front-end and
              back-end? Join us as a Full Stack Web Developer and become part of
              our team. At Unique IIT, we offer a dynamic work environment where
              you can experience personal and professional growth while working
              on exciting web projects.
            </p>
            <div style={{textAlign: "center"}}>
              <a
                href="/job"
                target="_blank"
                className="officebutton contact-us-btn"
              >
                <i className="fab fa-contact" /> Apply Today
              </a>
            </div>
          </div>
        </section>
      </section>
    </section>
  );
};

export default Career;
