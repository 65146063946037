import React, { useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import IconOne from "../../assets/images/service-icon/Web-app-development.png";
import IconTwo from "../../assets/images/service-icon/cloud-infrastructure.png";
import IconThree from "../../assets/images/service-icon/digital-strategy.png";
import Iconfive from "../../assets/images/service-icon/software-assurance.png";
import Iconsix from "../../assets/images/service-icon/ui-ux-design.png";
import IconFour from "../../assets/images/service-icon/ux.png";
import StickyMenu from "../../lib/StickyMenu";

function ServicesHomeOne({ className, action }) {
    useEffect(() => {
        StickyMenu();
    });
  return (
    <>
      <section className={`appie-service-area ${className}`} id="service">
        <div className="container services">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="appie-section-title text-center">
                <span className="uniqueiit-span">What we offer</span>
                <h3 className="appie-title">
                  Provide Awesome Service <br /> With Our Tools
                </h3>
                <p>
                  The full monty spiffing good time no biggie cack grub
                  fantastic.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service text-center mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="icon">
                  <img src={IconOne} alt="" />
                  <span>1</span>
                </div>
                <h4 className="appie-title">
                  Web, App and Software Development
                </h4>
                <p>
                  From concept to deployment, we bring your ideas to life
                  through cutting-edge web and app development. Our solutions
                  are responsive, scalable, and tailored to meet your business
                  needs.
                </p>
                <a href="" className="service-section-readmore">
                  <span>
                    Read more{" "}
                    <i>
                      <FaArrowRight />{" "}
                    </i>
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="icon">
                  <img src={IconTwo} alt="" />
                  <span>2</span>
                </div>
                <h4 className="appie-title">Cloud Infrastructure</h4>
                <p>
                  Embrace the power of cloud technology with our infrastructure
                  solutions. We optimize, deploy, and manage cloud environments,
                  enabling you to scale effortlessly and leverage the benefits
                  of cloud computing.
                </p>
                <a href="" className="service-section-readmore">
                  <span>
                    Read more{" "}
                    <i>
                      <FaArrowRight />{" "}
                    </i>
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="600ms"
              >
                <div className="icon">
                  <img src={IconThree} alt="" />
                  <span>3</span>
                </div>
                <h4 className="appie-title">Digital Strategy</h4>
                <p>
                  Navigate the digital landscape confidently with our strategic
                  insights. We analyze trends and consumer behavior to formulate
                  data-driven strategies that drive growth and maximize your
                  online potential.
                </p>
                <a href="" className="service-section-readmore">
                  <span>
                    Read more{" "}
                    <i>
                      <FaArrowRight />{" "}
                    </i>
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="800ms"
              >
                <div className="icon">
                  <img src={IconFour} alt="" />
                  <span>4</span>
                </div>
                <h4 className="appie-title">Website Design</h4>
                <p>
                  Our expert team of designers crafts visually appealing and
                  user-centric websites that make a lasting impression. We blend
                  creativity with functionality to ensure your online presence
                  stands out.
                </p>
                <a href="" className="service-section-readmore">
                  <span>
                    Read more{" "}
                    <i>
                      <FaArrowRight />
                    </i>
                  </span>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="600ms"
              >
                <div className="icon">
                  <img src={Iconfive} alt="" />
                  <span>5</span>
                </div>
                <h4 className="appie-title">Software Assurance</h4>
                <p>
                  Ensure the reliability and security of your software
                  applications with our thorough software assurance services. We
                  rigorously test and assess your software to identify
                  vulnerabilities and enhance performance.
                </p>
                <a href="" className="service-section-readmore">
                  <span>
                    Read more{" "}
                    <i>
                      <FaArrowRight />{" "}
                    </i>
                  </span>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service text-center mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="icon">
                  <img src={Iconsix} alt="" />
                  {/* <i className='fab fa-acorn'>h</i> */}
                  <span>6</span>
                </div>
                <h4 className="appie-title">UI/UX Design</h4>
                <p>
                  Elevate user experiences with our intuitive UI/UX designs. We
                  focus on creating interfaces that are not only aesthetically
                  pleasing but also provide seamless navigation, enhancing user
                  engagement.
                </p>
                <a href="" className="service-section-readmore">
                  <span>
                    Read more{" "}
                    <i>
                      <FaArrowRight />{" "}
                    </i>
                  </span>
                </a>
                {/* <div
        onClick={(e) => action(e)}
        className="toggle-btn ml-30 canvas_open d-lg-none d-block"
      >
        <i className="fa fa-bars" />
      </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
}

export default ServicesHomeOne;
