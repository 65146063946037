import React from "react";

const Privacy = ({ className }) => {
  return (
    <section className={`uniqueiit-hero-area  ${className || ""}`}>
      <section className="shadow-blue white-bg padding">
        <div className="section-title text-center">
          <h2 className="title">
            Privacy & Policy <span>Unique IIT</span>
          </h2>
        </div>
        <h4>Last Updated Dec 2023</h4>
        <hr />
        <h6>
          Thank you for choosing UniqueIIT. This Privacy Policy outlines how we
          collect, use, disclose, and protect information obtained from our
          users on our website and through our services.
        </h6>
        <h5>Information We Collect</h5>
        <li>
          Personal Information: When you engage with UniqueIIT, we may collect
          personal information such as names, email addresses, contact details,
          and any other information voluntarily provided by you.
        </li>
        <li>
          Usage Data: We may gather information on how you interact with our
          website and services, including IP addresses, browser information,
          pages viewed, and session duration, to enhance user experience and
          improve our services.
        </li>
        <h5>Use of Information</h5>
        <h6>We use the collected information for the following purposes:</h6>
        <li>To provide and maintain our services</li>
        <li>To personalize user experience and improve our website</li>
        <li>
          To communicate with users regarding updates, services, and inquiries
        </li>
        <li>To analyze and enhance our marketing strategies</li>
        <h5>Data Protection and Security</h5>
        <p>
          We are committed to safeguarding your information and employ
          industry-standard security measures to protect against unauthorized
          access, alteration, disclosure, or destruction of your personal data.
        </p>
        <h5>Disclosure to Third Parties</h5>
        <p>
          We do not sell, trade, or otherwise transfer your personally
          identifiable information to outside parties without your consent.
          However, non-personally identifiable visitor information may be shared
          with trusted third parties for statistical analysis or other
          legitimate business purposes.
        </p>
        <h5>Cookies and Tracking Technologies</h5>
        <p>UniqueIIT may use cookies and similar tracking technologies to enhance user experience. Users have the option to control cookie preferences through their web browser settings.</p>
        <h5>Third-Party Links</h5>
        <p>Our website may contain links to third-party sites. Please note that we are not responsible for the content or privacy practices of these sites. We encourage users to read the privacy policies of any third-party site they visit.</p>
        <h5>Consent</h5>
        <p>By using our website and services, you consent to the terms outlined in this Privacy Policy.</p>
        <h5>Changes to This Privacy Policy</h5>
        <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be reflected with a revised effective date on this page.</p>
        <h5>Contact Us</h5>
        <p>If you have any questions or concerns regarding this Privacy Policy or our data practices, please <a href="/contact"><b>Contact Us</b></a> at</p>

      </section>
    </section>
  );
};

export default Privacy;
