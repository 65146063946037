import React, { useEffect } from 'react'
import useToggle from '../Hooks/useToggle'
import FooterHomeOne from '../components/HomeOne/FooterHomeOne'
import HomeOneHeader from '../components/HomeOne/HomeOneHeader'
import ProjectPortfolio from '../components/HomeOne/ProjectPortfolio'
import Drawer from '../components/Mobile/Drawer'
import StickyMenu from '../lib/StickyMenu'

const projectPage = () => {
  useEffect(() => {
    StickyMenu();
});
const [drawer, drawerAction] = useToggle(false);
  return (
    <div>
       <Drawer drawer={drawer} action={drawerAction.toggle} />
        <HomeOneHeader action={drawerAction.toggle} className="servicenavbar"/>

        <ProjectPortfolio/>
        <FooterHomeOne/>
    </div>
  )
}

export default projectPage